import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import Loadable from '@/components/Loadable'

const CustomSpecialties = Loadable(
  lazy(() => import('../pages/CustomSpecialties/CustomSpecialties.page')),
  true,
)

const ChangeHistory = Loadable(
  lazy(() => import('../pages/ChangeHistory/ChangeHistory.page')),
  true,
)

const AppointmentLimitCustom = Loadable(
  lazy(
    () => import('../pages/AppointmentLimitCustom/AppointmentLimitCustom.page'),
  ),
  true,
)

const WhiteLabelConfigs = Loadable(
  lazy(() => import('../pages/WhiteLabelConfigs/WhiteLabelConfigs.page')),
)

const ClinicRoutes: RouteObject = {
  path: 'clinica',
  children: [
    {
      path: 'especialidades-customizadas',
      element: <CustomSpecialties />,
    },
    {
      path: 'especialidades-customizadas/historico-alteracoes',
      element: <ChangeHistory />,
    },
    {
      path: 'customizacao-limite-atendimento',
      element: <AppointmentLimitCustom />,
    },
    {
      path: 'white-label',
      element: <WhiteLabelConfigs />,
    },
  ],
}

export default ClinicRoutes
