export const headerItemsWithParams = [
  {
    path: '/aprovacoes',
    title: 'Aprovações',
    icon: 'file',
  },
  {
    path: '/clinica/white-label',
    title: 'Configurações WhiteLabel',
    icon: 'clipboard',
  },
  {
    path: '/faturamento/regras-cadastradas',
    title: 'Regras cadastradas',
    icon: 'clipboard',
  },
  {
    path: '/modularizacao/editar-template',
    title: 'Configuração de prontuário',
    icon: 'clipboard',
  },
  {
    path: '/modularizacao/visualizar-template',
    title: 'Configuração de prontuário',
    icon: 'clipboard',
  },
]

export const headerItems = {
  '/faturamento/contratos-faturamento': {
    title: 'Contratos de faturamento',
    icon: 'clipboard',
  },
  '/faturamento/boas-vindas': {
    title: 'Cadastrar contratos',
    icon: 'clipboard',
  },
  '/faturamento/extrato-faturamento/gerar-extrato': {
    title: 'Extrato de Faturamento',
    icon: 'clipboard',
  },
  '/faturamento/extrato-faturamento': {
    title: 'Extrato de Faturamento',
    icon: 'clipboard',
  },
  '/faturamento/cadastro-contrato': {
    title: 'Cadastrar contratos',
    icon: 'clipboard',
  },
  '/faturamento/extrato-faturamento/gerar-extrato/visualizar': {
    title: 'Extrato de Faturamento',
    icon: 'clipboard',
  },
  '/faturamento/visualizar-contratos': {
    title: 'Regras cadastradas',
    icon: 'clipboard',
  },
  '/faturamento/selecionar-categorias': {
    title: 'Cadastrar regras',
    icon: 'clipboard',
  },
  '/faturamento/anexar-arquivo': {
    title: 'Cadastrar contratos',
    icon: 'clipboard',
  },
  '/faturamento/vigencia-do-contrato': {
    title: 'Cadastrar contratos',
    icon: 'clipboard',
  },
  '/faturamento/cadastrar-regras/informacoes-gerais': {
    title: 'Cadastrar regras',
    icon: 'clipboard',
  },
  '/faturamento/cadastrar-regras/regras-atendimento': {
    title: 'Cadastrar regras',
    icon: 'clipboard',
  },
  '/faturamento/cadastrar-regras/regras-vidas': {
    title: 'Cadastrar regras',
    icon: 'clipboard',
  },
  '/faturamento/cadastrar-regras/regras-licencas': {
    title: 'Cadastrar regras',
    icon: 'clipboard',
  },
  '/faturamento/cadastrar-regras/regras-resumo': {
    title: 'Cadastrar regras',
    icon: 'clipboard',
  },
  '/faturamento/faturamento-por-vidas': {
    title: 'Faturamento por vidas',
    icon: 'clipboard',
  },
  '/faturamento/gerar-extrato': {
    title: 'Extrato de Faturamento',
    icon: 'clipboard',
  },

  '/conta/contas': {
    title: 'Conta',
    icon: 'clipboard',
  },
  '/conta/criar-conta': {
    title: 'Cadastrar uma conta',
    icon: 'clipboard',
  },

  '/modularizacao/home': {
    title: 'Configuração de prontuário',
    icon: 'clipboard',
  },
  '/modularizacao/criar-template': {
    title: 'Configuração de prontuário',
    icon: 'clipboard',
  },

  '/atendimento/agendar/consulta': {
    title: 'Agendamento de consultas',
    icon: 'clipboard',
  },
  '/atendimento/agendar/consulta/profissionais': {
    title: 'Agendamento de consultas',
    icon: 'clipboard',
  },
  '/atendimento/atendimento-log': {
    title: 'Log de atendimentos',
    icon: 'clipboard',
  },
  '/atendimento/prontuario': {
    title: 'Prontuário',
    icon: 'clipboard',
  },
  '/atendimento/logs-comunicacao': {
    title: 'Logs de comunicação',
    icon: 'clipboard',
  },
  '/atendimento/fila-atendimento': {
    title: 'Fila de atendimento',
    icon: 'clipboard',
  },
  '/atendimento/historico-atendimentos': {
    title: 'Histórico de agendamentos',
    icon: 'receipt',
  },
  '/atendimento/encaminhamento-medico': {
    title: 'Encaminhamento médico',
    icon: 'receipt',
  },
  '/atendimento/encaminhamento-medico/detalhes': {
    title: 'Encaminhamento médico',
    icon: 'receipt',
  },

  '/clinica/especialidades-customizadas': {
    title: 'Especialidades customizadas',
    icon: 'receipt',
  },
  '/clinica/especialidades-customizadas/historico-alteracoes': {
    title: 'Histórico de alterações',
    icon: 'receipt',
  },
  '/clinica/customizacao-limite-atendimento': {
    title: 'Limite de atendimento',
    icon: 'hospital',
  },

  '/dashboard/atendimento': {
    title: 'Dashboard',
    icon: 'dashboard',
  },
  '/dashboard/atendimentos': {
    title: 'Dashboard',
    icon: 'dashboard',
  },
  '/dashboard/solicitacoes': {
    title: 'Dashboard',
    icon: 'dashboard',
  },

  '/cupom/gerar-cupons': {
    title: 'Cupom',
    icon: 'file-exams',
  },

  '/audit-tracker': {
    title: 'Audit tracker',
    icon: 'clipboard',
  },
}
