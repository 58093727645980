import React, { FunctionComponent } from 'react'
import { useAuthContext } from '@/contexts/Auth'
import { useNavigate, useLocation } from 'react-router-dom'
import { generateUrlWithIds } from '@/utils/generateUrl'
import { Header, Progress } from '@conexasaude/conexa-hero'
import { headerItems, headerItemsWithParams } from './constants'

const PageHeader: FunctionComponent = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { loadingBar } = useAuthContext()

  const actionMenu = [
    {
      id: '1',
      label: 'Sair',
      onClick: () => {
        navigate('/logout', { replace: true })
      },
    },
  ]

  headerItemsWithParams.forEach(item => {
    const pathWithParams = generateUrlWithIds(item.path)

    if (pathWithParams) {
      headerItems[pathWithParams] = item
    }
  })

  return (
    <>
      <Header
        actionListItemAvatar={actionMenu}
        avatar
        title={headerItems?.[location.pathname]?.title}
        username="Username"
        leftIcon={headerItems?.[location.pathname]?.icon}
      />

      {loadingBar > 0 && <Progress />}
    </>
  )
}

export default PageHeader
