import { NavItemType } from '@/router/type'

const couponRouterItems: NavItemType[] = [
  {
    id: 'coupon',
    title: 'Cupom',
    url: '/cupom/gerar-cupons',
    icon: 'file-exams',
    type: 'item',
    pathname: '/cupom',
  },
]

export default couponRouterItems
