import { NavItemType } from '@/router/type'

const approvalsRouterItems: NavItemType[] = [
  {
    id: 'approvals',
    title: 'Aprovações',
    url: '/aprovacoes/lista-aprovacoes',
    icon: 'clipboard',
    type: 'item',
    pathname: '/aprovacoes',
  },
]

export default approvalsRouterItems
