export const TOKEN_STORAGE_KEY = '@Conexa:token'

export const USER_STORAGE_KEY = '@Conexa:user'

export const INITIAL_QUERY_PARAMS = {
  page: 1,
  itemsPerPage: 10,
}

export const INITIAL_DATA_RESULTS = {
  page: 1,
  length: 0,
  pageQuantity: 0,
  total: 0,
  itemsPerPage: 10,
}

export const FILE_LIMIT_SIZE_10MB = 10000000

export const ONE_HOUR_IN_MILLISECONDS = 3600000

export const IS_PRODUCTION = process.env.REACT_APP_ENV_NAME === 'production'
