import {
  createContext,
  useState,
  FunctionComponent,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { IAuthContext, StorageKeys } from './types'
import { useSearchParams } from 'react-router-dom'
import { tokenRegisterInvoicingApi } from '@/services/invoicingApi'
import { tokenRegisterCoreApi } from '@/services/coreApi'
import { tokenRegisterAuditTrackerApi } from '@/services/auditTrackerApi'

const AuthContext = createContext<IAuthContext>(null)

export const AuthProvider: FunctionComponent = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [loadingBar, setLoadingBar] = useState(0)
  const [authData, setAuthData] = useState(() => {
    return {
      token: localStorage.getItem(StorageKeys.token),
      userId: localStorage.getItem(StorageKeys.userId),
      refreshToken: localStorage.getItem(StorageKeys.refreshToken),
    }
  })

  useEffect(() => {
    const tokenParams = searchParams.get('token')
    const userIdParams = searchParams.get('userId')
    const refreshTokenParams = searchParams.get('refreshToken')

    if (tokenParams) {
      setAuthData({
        token: tokenParams,
        userId: userIdParams,
        refreshToken: refreshTokenParams,
      })

      localStorage.setItem(StorageKeys.token, tokenParams)
      localStorage.setItem(StorageKeys.userId, userIdParams)
      localStorage.setItem(StorageKeys.refreshToken, refreshTokenParams)
      searchParams.delete('token')
      searchParams.delete('userId')
      searchParams.delete('refreshToken')
      setSearchParams(searchParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    tokenRegisterInvoicingApi(authData?.token)
    tokenRegisterCoreApi(authData?.token)
    tokenRegisterAuditTrackerApi(authData?.token)
  }, [authData?.token])

  const handleLoadingBar = useCallback((action: 'add' | 'remove') => {
    if (action === 'add') {
      setLoadingBar(prev => prev + 1)
    } else {
      setLoadingBar(prev => prev - 1)
    }
  }, [])

  const valueProvider = useMemo(
    () => ({
      authData,
      loadingBar,
      handleLoadingBar,
    }),
    [authData, loadingBar, handleLoadingBar],
  )

  return (
    <AuthContext.Provider value={valueProvider}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = (): IAuthContext => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider')
  }
  return context
}
