import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import Loadable from '@/components/Loadable'

const AuditTracker = Loadable(
  lazy(() => import('../pages/AuditTracker/AuditTracker.page')),
  true,
)

const AuditTrackerRoutes: RouteObject = {
  path: 'audit-tracker',
  element: <AuditTracker />,
}

export default AuditTrackerRoutes
