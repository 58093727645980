import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import Loadable from '@/components/Loadable'

const Home = Loadable(
  lazy(() => import('../pages/Home')),
  true,
)
const TemplateConfig = Loadable(
  lazy(() => import('../pages/TemplateConfig')),
  true,
)

const ModularizationRoutes: RouteObject = {
  path: '/modularizacao',
  children: [
    {
      path: 'home',
      element: <Home />,
    },
    {
      path: 'editar-template/:templateId',
      element: <TemplateConfig />,
    },
    {
      path: 'visualizar-template/:templateId',
      element: <TemplateConfig />,
    },
  ],
}

export default ModularizationRoutes
