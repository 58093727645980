import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import Loadable from '@/components/Loadable'

const Approvals = Loadable(
  lazy(() => import('../pages/Approvals/Approvals.page')),
  true,
)

const ApprovalDetails = Loadable(
  lazy(() => import('../pages/ApprovalDetails/ApprovalDetails.page')),
  true,
)

const ApprovalsRoutes: RouteObject = {
  path: 'aprovacoes',
  children: [
    {
      path: 'lista-aprovacoes',
      element: <Approvals />,
    },
    {
      path: 'lista-aprovacoes/detalhes/:solicitationId',
      element: <ApprovalDetails />,
    },
  ],
}

export default ApprovalsRoutes
