import { NavItemType } from '@/router/type'

const dashboardRouterItems: NavItemType[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    icon: 'dashboard',
    type: 'collapse',
    pathname: '/dashboard',
    children: [
      {
        id: 'dashboardTelemedicine',
        title: 'Atendimento',
        url: '/dashboard/atendimento',
        type: 'item',
      },
      {
        id: 'dashboardScheduled',
        title: 'Atendimentos',
        url: '/dashboard/atendimentos',
        type: 'item',
      },
      {
        id: 'requestsAppointment',
        title: 'Solicitações',
        url: '/dashboard/solicitacoes',
        type: 'item',
      },
    ],
  },
]

export default dashboardRouterItems
