import { NavItemType } from '@/router/type'

const accountRouterItems: NavItemType[] = [
  {
    id: 'account',
    title: 'Conta',
    url: '/conta/contas',
    icon: 'user',
    type: 'item',
    pathname: '/conta',
  },
]

export default accountRouterItems
