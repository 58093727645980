import { FunctionComponent } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import RequireAuthentication from './guards/RequireAuthentication'
import InvoicingRoutes from '@/modules/Invoicing/routes/Router'
import AccountRoutes from '@/modules/Account/routes/Router'
import ModularizationRoutes from '@/modules/Modularization/routes/Router'
import ClinicRoutes from '@/modules/Clinic/routes/Router'
import ApprovalsRoutes from '@/modules/Approvals/routes/Router'
import TelemedicineRoutes from '@/modules/Telemedicine/routes/Router'
import DashboardRoutes from '@/modules/Dashboard/routes/Router'
import CouponRoutes from '@/modules/Coupon/routes/Router'
import AuditTrackerRoutes from '@/modules/AuditTracker/routes/Router'
// PLOP WILL AUTO-IMPORT YOUR ROUTES HERE. DO NOT REMOVE

const MainRoutes: FunctionComponent = () => {
  return useRoutes([
    {
      children: [
        InvoicingRoutes,
        AccountRoutes,
        ModularizationRoutes,
        ClinicRoutes,
        ApprovalsRoutes,
        TelemedicineRoutes,
        DashboardRoutes,
        CouponRoutes,
        AuditTrackerRoutes,
        // PLOP WILL AUTO-ADDED YOUR ROUTES HERE. DO NOT REMOVE
      ],
      element: <RequireAuthentication />,
    },
    {
      element: <Navigate to="/faturamento/contratos-faturamento" />,
      path: '*',
    },
  ])
}

export default MainRoutes
