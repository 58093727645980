import { toast } from '@conexasaude/conexa-hero'
import { AxiosError } from 'axios'
import { MutationCache, QueryCache, QueryClient } from 'react-query'

const handleOnError = (error: AxiosError) => {
  return toast({
    message:
      error?.response?.data?.msg ||
      'Aconteceu um problema inesperado, sentimos muito.',
    variant: 'error',
  })
}

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: AxiosError) => {
      handleOnError(error)
    },
  }),
  mutationCache: new MutationCache({
    onError: (error: AxiosError) => {
      handleOnError(error)
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export default queryClient
