import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import Loadable from '@/components/Loadable'

const Accounts = Loadable(
  lazy(() => import('../pages/Accounts')),
  true,
)

const CreateAccount = Loadable(
  lazy(() => import('../pages/CreateAccount')),
  true,
)

const AccountRoutes: RouteObject = {
  path: 'conta',
  children: [
    {
      path: 'contas',
      element: <Accounts />,
    },
    {
      path: 'criar-conta',
      element: <CreateAccount />,
    },
  ],
}

export default AccountRoutes
