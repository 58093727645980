import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import Loadable from '@/components/Loadable'

const ScheduleAppointment = Loadable(
  lazy(() => import('../pages/ScheduleAppointment/ScheduleAppointment.page')),
  true,
)

const ProfessionalAppointment = Loadable(
  lazy(
    () =>
      import('../pages/ProfessionalAppointment/ProfessionalAppointment.page'),
  ),
  true,
)

const AppointmentLogs = Loadable(
  lazy(() => import('../pages/AppointmentLogs/AppointmentLogs.page')),
  true,
)

const MedicalRecord = Loadable(
  lazy(() => import('../pages/MedicalRecord/MedicalRecord.page')),
  true,
)

const CommunicationLogs = Loadable(
  lazy(() => import('../pages/CommunicationLogs/CommunicationLogs.page')),
  true,
)

const AppointmentQueue = Loadable(
  lazy(() => import('../pages/AppointmentQueue/AppointmentQueue.page')),
  true,
)

const HistoryAppointment = Loadable(
  lazy(() => import('../pages/HistoryAppointment/HistoryAppointment.page')),
  true,
)

const MedicalReferral = Loadable(
  lazy(() => import('../pages/MedicalReferral/MedicalReferral.page')),
  true,
)

const MedicalReferralDetails = Loadable(
  lazy(
    () =>
      import(
        '../pages/MedicalReferral/components/MedicalReferralDetails/MedicalReferralDetails.component'
      ),
  ),
  true,
)

const TelemedicineRoutes: RouteObject = {
  path: 'atendimento',
  children: [
    {
      path: 'agendar/consulta',
      element: <ScheduleAppointment />,
    },
    {
      path: 'agendar/consulta/profissionais',
      element: <ProfessionalAppointment />,
    },
    { path: 'atendimento-log', element: <AppointmentLogs /> },
    {
      path: 'prontuario',
      element: <MedicalRecord />,
    },
    {
      path: 'logs-comunicacao',
      element: <CommunicationLogs />,
    },
    {
      path: 'fila-atendimento',
      element: <AppointmentQueue />,
    },
    { path: 'historico-atendimentos', element: <HistoryAppointment /> },
    { path: 'encaminhamento-medico', element: <MedicalReferral /> },
    {
      path: 'encaminhamento-medico/detalhes',
      element: <MedicalReferralDetails />,
    },
  ],
}

export default TelemedicineRoutes
