import { createContext, FunctionComponent, useContext, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

interface IThemeContext {
  embed: { value: boolean }
}

const ThemeContext = createContext<IThemeContext>(null)

export const ThemeProvider: FunctionComponent = ({ children }) => {
  const [searchParams] = useSearchParams()

  const embed = useMemo(() => {
    return {
      value: !!searchParams.get('embedFull'),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const valueProvider = useMemo(
    () => ({
      embed,
    }),
    [embed],
  )

  return (
    <ThemeContext.Provider value={valueProvider}>
      {children}
    </ThemeContext.Provider>
  )
}

export const useThemeContext = (): IThemeContext => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider')
  }
  return context
}
