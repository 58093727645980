import React, { FunctionComponent } from 'react'
import { useThemeContext } from '@/theme/context/ThemeContext'
import PageHeader from '@/components/PageHeader'
import useHeight from '@/utils/useHeight'

const MainLayout: FunctionComponent = ({ children }) => {
  const height = useHeight()
  const { embed } = useThemeContext()

  return (
    <>
      {!embed?.value && (
        <div className="pageHeader" id="pageHeader">
          <PageHeader />
        </div>
      )}

      <div
        className={!embed?.value ? 'mainContainer' : 'mainContainerEmbed'}
        id="mainContainer"
        style={{ minHeight: height }}
      >
        {children}
      </div>
    </>
  )
}

export default MainLayout
