import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'

const currentErrors = [
  'Request failed with status code',
  '400',
  '422',
  'timeout',
  "Failed to execute 'insertBefore' on 'Node'",
  "Failed to execute 'removeChild' on 'Node'",
]

const regexErrors = new RegExp(currentErrors.join('|'), 'mi')

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
const isProduction = process.env.REACT_APP_ENV_NAME === 'production'

isProduction &&
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        matchRoutes,
        createRoutesFromChildren,
      }),
    ],
    ignoreErrors: [regexErrors],
    // Performance Monitoring
    tracesSampleRate: 0.2, //  Capture 20% of the transactions
  })
