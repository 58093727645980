import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import Loadable from '@/components/Loadable'

const ContractRegistration = Loadable(
  lazy(() => import('../pages/ContractInvoicing')),
  true,
)

const WelcomeContract = Loadable(
  lazy(() => import('../pages/WelcomeContract')),
  true,
)

const RegisteredRules = Loadable(
  lazy(() => import('../pages/RegisteredRules')),
  true,
)

const RegisteredContracts = Loadable(
  lazy(() => import('../pages/RegisteredContracts')),
  true,
)

const SelectionCategory = Loadable(
  lazy(() => import('../pages/SelectionCategory')),
  true,
)

const ValidityContracts = Loadable(
  lazy(() => import('../pages/ValidityContracts')),
  true,
)

const AttachFiles = Loadable(
  lazy(() => import('../pages/AttachFiles')),
  true,
)

const RulesAppointment = Loadable(
  lazy(() => import('../pages/RulesAppointment/RulesAppointment.page')),
  true,
)

const GeneralInformation = Loadable(
  lazy(() => import('../pages/GeneralInformation')),
  true,
)

const RulesLives = Loadable(
  lazy(() => import('../pages/RulesLives/RulesLives.component')),
  true,
)

const RulesLicenses = Loadable(
  lazy(() => import('../pages/RulesLicenses')),
  true,
)

const RulesSummary = Loadable(
  lazy(() => import('../pages/RulesSummary')),
  true,
)

const BillingStatement = Loadable(
  lazy(() => import('../pages/BillingStatement')),
  true,
)

const GenerateExtract = Loadable(
  lazy(() => import('../pages/GenerateExtract')),
  true,
)

const StatementWithValues = Loadable(
  lazy(() => import('../pages/StatementWithValues')),
  true,
)

const BillingPerLives = Loadable(
  lazy(() => import('../pages/BillingPerLives')),
  true,
)

const InvoicingRoutes: RouteObject = {
  path: 'faturamento',
  children: [
    {
      path: 'contratos-faturamento',
      element: <ContractRegistration />,
    },
    {
      path: 'boas-vindas',
      element: <WelcomeContract />,
    },

    {
      path: 'regras-cadastradas',
      element: <RegisteredRules />,
    },
    {
      path: 'visualizar-contratos',
      element: <RegisteredContracts />,
    },
    {
      path: 'selecionar-categorias',
      element: <SelectionCategory />,
    },
    {
      path: 'vigencia-do-contrato',
      element: <ValidityContracts />,
    },
    {
      path: 'anexar-arquivo',
      element: <AttachFiles />,
    },
    {
      path: 'cadastrar-regras/regras-atendimento',
      element: <RulesAppointment />,
    },
    {
      path: 'cadastrar-regras/informacoes-gerais',
      element: <GeneralInformation />,
    },
    {
      path: 'cadastrar-regras/regras-vidas',
      element: <RulesLives />,
    },
    {
      path: 'cadastrar-regras/regras-licencas',
      element: <RulesLicenses />,
    },
    {
      path: 'cadastrar-regras/regras-resumo',
      element: <RulesSummary />,
    },
    {
      path: 'extrato-faturamento',
      element: <GenerateExtract />,
    },
    {
      path: 'extrato-faturamento/gerar-extrato',
      element: <BillingStatement />,
    },
    {
      path: 'extrato-faturamento/gerar-extrato/visualizar',
      element: <StatementWithValues />,
    },
    {
      path: 'faturamento-por-vidas',
      element: <BillingPerLives />,
    },
  ],
}

export default InvoicingRoutes
