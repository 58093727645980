import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import Loadable from '@/components/Loadable'

const Telemedicine = Loadable(
  lazy(() => import('../pages/Telemedicine/Telemedicine.page')),
  true,
)

const ScheduledAppointment = Loadable(
  lazy(() => import('../pages/ScheduledAppointment/ScheduledAppointment.page')),
  true,
)

const RequestsAppointment = Loadable(
  lazy(() => import('../pages/RequestsAppointment/RequestsAppointment.page')),
  true,
)

const DashboardRoutes: RouteObject = {
  path: 'dashboard',
  children: [
    {
      path: 'atendimento',
      element: <Telemedicine />,
    },
    {
      path: 'atendimentos',
      element: <ScheduledAppointment />,
    },
    {
      path: 'solicitacoes',
      element: <RequestsAppointment />,
    },
  ],
}

export default DashboardRoutes
