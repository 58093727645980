import { NavItemType } from '@/router/type'

const invoicingRouterItems: NavItemType[] = [
  {
    id: 'invoicing',
    title: 'Faturamento',
    icon: 'clipboard',
    type: 'collapse',
    pathname: '/faturamento',
    children: [
      {
        id: 'contract',
        title: 'Contratos de Faturamento',
        url: '/faturamento/contratos-faturamento',
        type: 'item',
      },
      {
        id: 'welcome',
        title: 'Cadastro de Contrato',
        url: '/faturamento/boas-vindas',
        type: 'item',
      },
      {
        id: 'extract',
        title: 'Extrato de Faturamento',
        url: '/faturamento/extrato-faturamento',
        type: 'item',
      },
      {
        id: 'billingPerLives',
        title: 'Faturamento por vidas',
        url: '/faturamento/faturamento-por-vidas',
        type: 'item',
      },
    ],
  },
]

export default invoicingRouterItems
