import { FunctionComponent, useCallback, useState } from 'react'
import { Menu as MenuIcon } from '@conexasaude/icons'
import { useNavigate } from 'react-router-dom'
import { NavItem } from '@/router/type'
import { ConexaLogo, Sidebar } from '@conexasaude/conexa-hero'
import menuItems from '@/router/menuItems'
import useStyles from './styles'

const NewNavBar: FunctionComponent = () => {
  const styles = useStyles()
  const [isExpanded, setIsExpanded] = useState(true)

  function handleMenuSize() {
    setIsExpanded(prevState => !prevState)
    const mainContainer = document.getElementById('mainContainer')
    const pageHeader = document.getElementById('pageHeader')

    if (isExpanded) {
      mainContainer.classList.add('active')
      pageHeader.classList.add('active')
    } else {
      mainContainer.classList.remove('active')
      pageHeader.classList.remove('active')
    }
  }

  const navigate = useNavigate()

  const handleLogo = useCallback(() => {
    return <ConexaLogo base="colorful" height={24} inverse width={110} />
  }, [])

  return (
    <div css={styles.wrapper}>
      <Sidebar.Root isExpanded={isExpanded}>
        <Sidebar.Brand>
          {isExpanded && handleLogo()}
          <MenuIcon onClick={handleMenuSize} aria-label="menu toggle" />
        </Sidebar.Brand>

        <Sidebar.List>
          {menuItems.map(item => {
            if (item.type === 'item') {
              return (
                <Sidebar.Trigger
                  onClick={() => navigate(item.url)}
                  key={item.id}
                  icon={item.icon}
                  isActive={location?.pathname?.includes(item?.pathname)}
                >
                  {item.title}
                </Sidebar.Trigger>
              )
            }

            return (
              <Sidebar.Collapse
                key={item.id}
                icon={item.icon}
                label={item.title}
                value={item.id}
                isActive={location?.pathname?.includes(item?.pathname)}
              >
                {item.children.map((subItem: NavItem) => (
                  <Sidebar.CollapseTrigger
                    key={subItem.id}
                    onClick={() => navigate(subItem.url)}
                    subItemActive={location?.pathname?.includes(subItem?.url)}
                  >
                    {subItem.title}
                  </Sidebar.CollapseTrigger>
                ))}
              </Sidebar.Collapse>
            )
          })}
        </Sidebar.List>
      </Sidebar.Root>
    </div>
  )
}

export default NewNavBar
