import axios from 'axios'
import { InterceptorEjectorType, TokenRegisterType } from './types'
import { StorageKeys } from '@/contexts/Auth/types'
import {
  handleLogoutEmbed,
  handleManyRequestsRefreshToken,
} from '@/utils/postMessages/handleLogoutEmbed'
import { applyAppTokenRefreshInterceptor } from '@conexasaude/apply-refresh-token'

const coreApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

export type HandleUnauthorizedType = (
  history: History,
) => InterceptorEjectorType

export const tokenRegisterCoreApi: TokenRegisterType = token => {
  const interceptorId = coreApi.interceptors.request.use(config => {
    if (!config.headers.token) {
      config.headers.token = token
    }

    return config
  })

  return () => coreApi.interceptors.request.eject(interceptorId)
}

const handleRefreshToken = async () => {
  const refreshToken = localStorage.getItem(StorageKeys?.refreshToken)
  const userId = localStorage.getItem(StorageKeys?.userId)

  const { data } = await coreApi.post('token/refresh', {
    usuario_id: userId,
    refreshToken: refreshToken,
    audience: 'WEB',
  })

  return data?.accessToken
}

applyAppTokenRefreshInterceptor({
  client: coreApi,
  options: {
    shouldIntercept(error) {
      return (
        error?.response?.status === 401 &&
        error?.response?.data?.msg?.includes('INVALID_ACCESS_TOKEN')
      )
    },
  },
  handleRefreshToken,
  onSuccess(accessToken) {
    tokenRegisterCoreApi(accessToken)
    localStorage.setItem(StorageKeys?.token, accessToken)
  },
  onError(error) {
    if (error?.response?.status === 429) {
      handleManyRequestsRefreshToken()
      return
    }

    if (error?.response?.status === 401) {
      console.log('handleRefreshToken error<>>', error)
      localStorage.clear()
      handleLogoutEmbed()
    }
  },
})

export default coreApi
