import { IS_PRODUCTION } from '@/constants'
import { useEffect, useState } from 'react'

export default function useHeight(): string {
  const [height, setHeight] = useState(
    window.innerHeight * (window.visualViewport?.scale || 1),
  )

  useEffect(() => {
    const onResize = () => {
      setHeight(window.innerHeight * (window.visualViewport?.scale || 1))
    }

    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  })

  if (!IS_PRODUCTION) {
    return height - 100 + 'px'
  }

  return height + 'px'
}
