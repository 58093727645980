import { NavItemType } from '@/router/type'

const telemedicineRouterItems: NavItemType[] = [
  {
    id: 'appointment',
    title: 'Atendimento',
    icon: 'clipboard',
    type: 'collapse',
    pathname: '/atendimento',
    children: [
      {
        id: 'scheduleAppointment',
        title: 'Agendar consulta',
        url: '/atendimento/agendar/consulta',
        type: 'item',
      },
      {
        id: 'appointmentLogs',
        title: 'Log atendimentos',
        url: '/atendimento/atendimento-log',
        type: 'item',
      },
      {
        id: 'medicalRecord',
        title: 'Prontuário',
        url: '/atendimento/prontuario',
        type: 'item',
      },
      {
        id: 'communicationLogs',
        title: 'Logs de comunicação',
        url: '/atendimento/logs-comunicacao',
        type: 'item',
      },
      {
        id: 'appointmentQueue',
        title: 'Fila de atendimento',
        url: '/atendimento/fila-atendimento',
        type: 'item',
      },
      {
        id: 'historyAppointment',
        title: 'Histórico de agendamentos',
        url: '/atendimento/historico-atendimentos',
        type: 'item',
      },
      {
        id: 'medicalReferral',
        title: 'Encaminhamento',
        url: '/atendimento/encaminhamento-medico',
        type: 'item',
      },
    ],
  },
]

export default telemedicineRouterItems
