import accountRouterItems from '@/modules/Account/routes/items'
import invoicingRouterItems from '@/modules/Invoicing/routes/items'
import { NavItemType } from './type'
import approvalsRouterItems from '@/modules/Approvals/routes/items'
import telemedicineRouterItems from '@/modules/Telemedicine/routes/items'
import dashboardRouterItems from '@/modules/Dashboard/routes/item'
import couponRouterItems from '@/modules/Coupon/routes/items'
import auditTrackerItems from '@/modules/AuditTracker/routes/items'

const menuItems: NavItemType[] = [
  ...dashboardRouterItems,
  ...invoicingRouterItems,
  ...accountRouterItems,
  ...approvalsRouterItems,
  ...telemedicineRouterItems,
  ...couponRouterItems,
  ...auditTrackerItems,
]

export default menuItems
