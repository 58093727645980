import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import Loadable from '@/components/Loadable'

const Coupon = Loadable(
  lazy(() => import('../pages/Coupon/Coupon.page')),
  true,
)

const CouponRoutes: RouteObject = {
  path: 'cupom',
  children: [
    {
      path: 'gerar-cupons',
      element: <Coupon />,
    },
  ],
}

export default CouponRoutes
