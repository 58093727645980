import { Text } from '@conexasaude/conexa-hero'
import { FunctionComponent } from 'react'
import useStyles from './styles'
import { Lock as LockIcon } from '@conexasaude/icons'

const NotPermission: FunctionComponent = () => {
  const styles = useStyles()

  return (
    <div css={styles.container}>
      <LockIcon />
      <Text renderAs="h2">Você não tem a permissão necessária</Text>
      <div css={styles.description}>
        <Text renderAs="span">
          Infelizmente você não tem acesso à essa tela ou funcionalidade, por
          favor entre em contato com o gestor da clínica ou com nosso time de
          atendimento.
        </Text>
      </div>
    </div>
  )
}

export default NotPermission
