export type TokenType = {
  token: string
  userId: string
  refreshToken: string
}

export interface SignInCredentials {
  email: string
  password: string
}

export interface IAuthContext {
  authData: TokenType | null
  loadingBar: number
  handleLoadingBar(action: string): void
}

export enum StorageKeys {
  token = '@Conexa:token',
  user = '@Conexa:user',
  userId = '@Conexa:userId',
  refreshToken = '@Conexa:refreshToken',
}
