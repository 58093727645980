import { Fragment, FunctionComponent } from 'react'
import { Global } from '@emotion/react'
import { ToastContainer } from '@conexasaude/hero-web'
import {
  ToastContainer as ConexaHeroToastContainer,
  EnvironmentIdentifier,
} from '@conexasaude/conexa-hero'
import { useThemeContext } from './theme/context/ThemeContext'
import useStyles from './styles/globalStyles'
import MainRoutes from './router/Router'
import NavBar from './components/NavBar'
import { ResetStyles } from '@conexasaude/styles'
import { IS_PRODUCTION } from './constants'

const App: FunctionComponent = () => {
  const { root } = useStyles()

  const { embed } = useThemeContext()

  return (
    <Fragment>
      <ResetStyles />
      <Global styles={root} />
      <ToastContainer
        position="top-right"
        toastStyles={{
          message: { fontSize: '16px', lineHeight: '24px' },
        }}
      />
      <div className="environmentIdentifier">
        <EnvironmentIdentifier disabled={IS_PRODUCTION || embed?.value} />
      </div>
      <ConexaHeroToastContainer position="top-right" />

      {!embed?.value && <NavBar />}

      <MainRoutes />
    </Fragment>
  )
}

export default App
