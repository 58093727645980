import { FunctionComponent, StrictMode } from 'react'
import { AuthProvider } from './contexts/Auth'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from './theme/context/ThemeContext'
import reportWebVitals from './reportWebVitals'
import ReactDOM from 'react-dom'
import App from './App'
import queryClient from './services/queryClient'
import { ReactQueryDevtools } from 'react-query/devtools'
import './i18n'
import '@/services/sentry'

const Index: FunctionComponent = () => (
  <StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ThemeProvider>
            <div className="appContainer">
              <App />
            </div>
          </ThemeProvider>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  </StrictMode>
)

ReactDOM.render(<Index />, document.getElementById('root'))

reportWebVitals()
