import { NavItemType } from '@/router/type'

const auditTrackerItems: NavItemType[] = [
  {
    id: 'audit-tracker',
    title: 'Audit Tracker',
    url: '/audit-tracker',
    icon: 'clipboard',
    type: 'item',
    pathname: '/audit-tracker',
  },
]

export default auditTrackerItems
