import { makeStyles, css } from '@conexasaude/hero'

const useStyles = makeStyles({
  container: () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;

    h2 {
      margin-top: 28px;
    }

    button {
      margin-top: 18px;
      padding: 10px;
    }
  `,

  description: css`
    max-width: 553px;
    line-height: 24px;
    text-align: center;
  `,
})

export default useStyles
