import { useAuthContext } from '@/contexts/Auth'
import { FunctionComponent } from 'react'
import { Outlet } from 'react-router-dom'
import MainLayout from '@/layout/MainLayout'
import NotPermission from '@/components/NotPermission'

const RequireAuthentication: FunctionComponent = () => {
  const { authData } = useAuthContext()

  return authData?.token ? (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ) : (
    <NotPermission />
  )
}

export default RequireAuthentication
