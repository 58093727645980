import { scrollbar } from './customScrollbar'
import calendarIcon from '@/assets/icons/calendar.svg'
import { IS_PRODUCTION } from '@/constants'
import { css, makeStyles } from '@conexasaude/conexa-hero'

const useStyles = makeStyles({
  root: ({ colors, spacing }) => {
    return css`
      body:fullscreen {
        overflow: auto;
      }
      body:-ms-fullscreen {
        overflow: auto;
      }
      body:-webkit-full-screen {
        overflow: auto;
      }
      body:-moz-full-screen {
        overflow: auto;
      }

      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type='date']::-webkit-calendar-picker-indicator {
        background: url(${calendarIcon}) no-repeat;
        background-size: 100%;
      }

      .appContainer {
        width: 100vw;
        height: 100vh;
        display: grid;
        overflow-x: hidden;
        background-color: ${colors.background.gray};
      }

      .mainContainer {
        display: flex;
        flex-direction: column;
        background-color: ${colors.background.gray};
        color: ${colors.neutral[900]};
        text-align: left;
        margin-left: 256px;
        transition: margin-left 0.4s;
        padding-left: ${spacing.xs};

        > div {
          margin-top: ${IS_PRODUCTION ? '100px' : '132px'};
          padding-right: ${spacing.xs};
        }
      }

      .mainContainer.active {
        margin-left: 85px;
        transition: margin-left 0.4s;
      }

      .mainContainerEmbed {
        padding-top: 15px;
        padding-right: 10px;
        background-color: ${colors.background.white};
      }

      .pageHeader {
        margin-left: 256px;
        transition: margin-left 0.4s;
        transition: width 0.4s;
        position: fixed;
        left: 0;
        right: 0;
        top: ${IS_PRODUCTION ? '0' : '32px'};
        z-index: 9;
      }

      .pageHeader.active {
        margin-left: 61px;
        transition: margin-left 0.4s;
        transition: width 0.4s;
      }

      .environmentIdentifier {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 9;
      }

      .colorPrimary {
        & .MuiLinearProgress-barColorPrimary {
          background-color: ${colors.brand.primary[300]};
        }
      }

      ${scrollbar()}

      .breakWord {
        white-space: pre-line;
      }

      .textLimitation {
        @media (max-width: 1366px) {
          max-width: 240px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        @media (min-width: 1367px) {
          max-width: 350px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        @media (max-width: 1024px) {
          max-width: 160px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    `
  },
})

export default useStyles
