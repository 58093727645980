import { IS_PRODUCTION } from './../../constants/index'
import { makeStyles, css } from '@conexasaude/conexa-hero'

const useStyles = makeStyles({
  wrapper: css`
    position: fixed;

    > div {
      height: calc(100vh - ${IS_PRODUCTION ? '0px' : '32px'});
      margin-top: ${IS_PRODUCTION ? '0' : '32px'};
    }
  `,
})

export default useStyles
